import { createWebHistory, createRouter } from 'vue-router'
const constantRoutes = [
    {
        path: '',
        redirect: '/mdlk',
    },
    {
        path:'/mdlk',
        component: ()=> import("@/views/yg/mdlk.vue")
    },
    {
        path:'/jyk',
        component: ()=> import("@/views/yg/jyk.vue")
    },
    {
        path:'/dlk',
        component: ()=> import("@/views/yg/dlk.vue")
    },
    {
        path:'/pbk',
        component: ()=> import("@/views/yg/pbk.vue")
    },
    {
        path:'/dlight',
        component: ()=> import("@/views/deng/dlight.vue")
    },
    {
        path:'/wlight',
        component: ()=> import("@/views/deng/wlight.vue")
    },
    {
        path:'/water',
        component: ()=> import("@/views/beng/water.vue")
    },
    {
        path:'/oxygen',
        component: ()=> import("@/views/beng/oxygen.vue")
    }
]

const router = createRouter({
    history:createWebHistory(),
    routes:constantRoutes
})

export default router