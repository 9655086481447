import { createApp } from 'vue'
import App from './App.vue'

//加入路由
import router from './router/index';

import Vant from 'vant';
import 'vant/lib/index.css';

// 自定义首页加载组件
import CustomLoadModel from "@/components/LoadModel/index.vue"

const app = createApp(App)

app.use(router)
app.use(Vant)

// 全局组件挂载
app.component('CustomLoadModel', CustomLoadModel)

app.mount('#app')