<!-- eslint-disable vue/multi-word-component-names -->
<style lang="less" scoped>
/deep/ .van-circle__text {
  color: #ffffff;
}
.load_box {
  width: 100%;
  height: 100%;
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    z-index: 10;
    background: linear-gradient(180deg, rgba(50, 70, 80, 0.85) 0, #0d101b 100%);
  }
  .load_container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      opacity: 1;
      transition: all 0.5s;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-color: transparent;
      background-image: url("../../assets/pattern.png");
      background-size: 4.6875rem;
      background-repeat: repeat;
      background-attachment: initial;
      z-index: 10;
      opacity: 0.8;
    }
    &::after {
      content: "";
      position: fixed;
      top: 0;
      height: 100%;
      left: 0;
      right: 0;
      z-index: 1;
      background-image: linear-gradient(
        180deg,
        rgba(50, 70, 80, 0.9) 0,
        #0d101b 100%
      );
      background-image: url("../../assets/cover1.jpg");
      background-repeat: no-repeat;
      background-position: center;
      background-attachment: initial;
      transition: background 0.2s linear;
      background-size: cover;
    }
    .top_text {
      background: url("../../assets/ydl_logo.png") no-repeat;
      background-size: contain;
      position: absolute;
      left: 4%;
      top: 3%;
      width: 90px;
      height: 45px;
      z-index: 10;
    }
    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      flex-flow: column;
      z-index: 100;
      color: transparent;
      font-weight: 700;
      letter-spacing: 4px;
      background: linear-gradient(183deg, #fff 35%, #fff);
      -webkit-background-clip: text;
      background-clip: text;
      font-size: 20px;
      margin-top: 20px;
      .title {
        margin: 20px 0;
        font-size: 16px;
        letter-spacing: 6px;
      }
      .subhead {
        font-size: 22px;
        letter-spacing: 5px;
      }
    }
    .code {
      color: #ffffff;
      z-index: 10;
      text-align: center;
      position: relative;
      bottom: 4%;
    }
  }
}
</style>


<template>
  <div v-if="show" class="load_box">
    <div class="load_container">
      <div class="top_text"></div>
      <div class="box">
        <van-circle
          size="90px"
          :current-rate="num"
          :rate="num"
          :text="num + '%'"
          :color="gradientColor"
          :stroke-width="60"
        />
        <div class="title">一道流水族科技</div>
        <div class="subhead">向海而生 为梦而来</div>
      </div>
      <div class="code">
        版权声明©right 2022-2024 一道流(广东)科技控股有限公司
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineExpose } from "vue";

let PercentInterval;
const show = ref(true);
const num = ref(1);
const gradientColor = {
  "0%": "#ffcf49",
  "100%": "#ff6504",
};

/**
 * 开始加载
 */
function start() {
  show.value = true;
  num.value = 1;
  PercentInterval = setInterval(() => {
    num.value += 1;
    if (num.value >= 98) {
      num.value = 98;
    }
  }, 1000);
}

/**
 * 结束加载
 */
function end() {
  num.value = 100;
  clearInterval(PercentInterval);
  setTimeout(() => {
    show.value = false;
  }, 1500);
}

/** 抛出，供外 */
defineExpose({
  start,
  end,
});
</script>